import React, { useState, useEffect } from "react"
import PageHeader from "../components/PageHeader"
import GiftAnimation, { GiftAnimationV2 } from "../components/GiftAnimation"
import { PageComponent } from "../components/PageComponent"
import LoadingComponent from "../components/LoadingComponent"
import GreetingMessageComponent from "../components/GreetingMessageComponent"

function checkURLHash(
  setPageNotFound,
  setGiftDetails,
  setGiftCard,
  setGiftWrapper,
  setGreetingCard,
  setGreetingMessage,
  setAudioTune,
  setFetchedAnimation,
  urlHash
) {
  fetch(`${process.env.GATSBY_BACKEND}/api/v1/cartitems/${urlHash}/`, {
    method: "GET",
    mode: "cors",
  })
    .then(response => {
      if (response.status === 200) {
        return response.json()
      }
      throw Error("Invalid URL")
    })
    .then(result => {
      const giftDetails = result
      setGiftDetails(giftDetails)
      if (giftDetails) {
        setGiftCard({
          brand_name: giftDetails.brand_name,
          product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.product_image}`,
          redemption_instructions: giftDetails.redemption_instructions,
        })
        if (giftDetails.greeting_message) {
          setGreetingMessage(giftDetails.greeting_message)
        }
        if (giftDetails.experience.status) {
          setGreetingCard(
            giftDetails.experience.greeting_card
              ? {
                  product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.greeting_card.front_image}`,
                  product_inner_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.greeting_card.inner_image}`,
                }
              : null
          )

          if (!giftDetails.greeting_message) {
            setGreetingMessage(
              (giftDetails.experience.greeting_card &&
                giftDetails.experience.greeting_card.message) ||
                ""
            )
          }
          setGiftWrapper(
            giftDetails.experience.gift_wrapper
              ? {
                  product_image: `${process.env.GATSBY_EGIFTIT_B2B}${giftDetails.experience.gift_wrapper.product_image}`,
                }
              : null
          )
          setAudioTune(giftDetails.experience.audio_tune || null)
          setFetchedAnimation(giftDetails.experience.animation || null)
        }
      } else {
        setPageNotFound(true)
      }
    })
    .catch(error => {
      console.log(error)
      setPageNotFound(true)
    })
}

export default function HomePage(props) {
  const [pageNotFound, setPageNotFound] = useState(false)
  const [giftDetails, setGiftDetails] = useState(null)
  const [giftcard, setGiftCard] = useState(null)
  const [giftwrapper, setGiftWrapper] = useState(null)
  const [greetingcard, setGreetingCard] = useState(null)
  const [greetingMessage, setGreetingMessage] = useState(null)
  const [audioTune, setAudioTune] = useState(null)
  const [fetchedAnimation, setFetchedAnimation] = useState(null)

  useEffect(() => {
    if (props.location.search) {
      const urlHash = props.location.search.replace("?", "")

      checkURLHash(
        setPageNotFound,
        setGiftDetails,
        setGiftCard,
        setGiftWrapper,
        setGreetingCard,
        setGreetingMessage,
        setAudioTune,
        setFetchedAnimation,
        urlHash
      )
    } else {
      window.location.replace(`${process.env.GATSBY_EGIFTIT_FRONTEND}/404/`)
    }
  }, [])

  return (
    <PageComponent className="received-gift-page">
      {!giftcard ? (
        <>
          {!pageNotFound ? (
            <LoadingComponent />
          ) : (
            window.location.replace(
              `${process.env.GATSBY_EGIFTIT_FRONTEND}/404/`
            )
          )}{" "}
        </>
      ) : (
        <>
          <PageHeader giftDetails={giftDetails} />
          {greetingMessage && (
            <GreetingMessageComponent greetingMessage={greetingMessage} />
          )}
          <GiftAnimationV2
            giftDetails={giftDetails}
            giftcard={giftcard}
            giftwrapper={giftwrapper}
            greetingcard={greetingcard}
            greetingMessage={greetingMessage}
            audioTune={audioTune}
            fetchedAnimation={fetchedAnimation}
          />
        </>
      )}
    </PageComponent>
  )
}
