import React from "react"
import styled from "styled-components"

const GreetingMessageWithoutCard = styled.p`
  text-align: center;
  font-family: "OpenSans-Bold";
  font-style: italic;
  color: #a09c9c;
  margin: 1rem 0;
  white-space: pre-line;
`

export default function (props) {
  return (
    <GreetingMessageWithoutCard>
      {props.greetingMessage}
    </GreetingMessageWithoutCard>
  )
}
